import React from 'react'
import { Tabs, Table, Button, DateRangePicker } from '@signpost/common/src/components'
import { Status } from 'components'
import { capitalize } from '@signpost/common/src/helpers/string'
import styled from 'styled-components'
import 'styles/Costs.css'

const RefreshWrapper = styled.div`
    display: inline-block;
    float: right
    margin: 0 0 20px 20px
`

const CostsPage = ({
    isLoaded,
    isFilterButtonActive,
    error,
    data,
    activeTabName,
    handleChooseTab,
    handleSelectDateRange,
    handleRefreshTable
}) => {
    const renderCustomColumns = type => {
        if (type === 'labs') {
            return [{ name: 'Name', bodyKey: 'name', width: 60 }]
        }

        return [
            { name: 'Email', bodyKey: 'email', width: 30 },
            { name: 'Full Name', bodyKey: 'fullName', width: 30 }
        ]
    }

    const tabsConfig = ['students', 'labs', 'teachers'].map(type => {
        return {
            name: type,
            label: capitalize(type),
            component: (
                <Status isLoaded={isLoaded} error={error}>
                    <Table
                        head={[
                            ...renderCustomColumns(type),
                            { name: 'Credits', bodyKey: 'creditsValue', width: 40 },
                        ]}
                        body={data[type]}
                    />
                </Status>
            )
        }
    })

    return (
        <div className="page-content" style={{ overflowX: 'visible' }}>
            <DateRangePicker isDisabled={!isLoaded} onChange={handleSelectDateRange} />
            <RefreshWrapper>
                <Button
                    text="Apply filter"
                    onClick={handleRefreshTable}
                    isDisabled={!isLoaded || !isFilterButtonActive}
                />
            </RefreshWrapper>
            <Tabs config={tabsConfig} activeTabName={activeTabName} handleChooseTab={handleChooseTab} />
        </div>
    )
}

export default CostsPage
