const setColor = string => {
    switch (string) {
        case 'Completed':
        case 'starting':
        case 'Started':
        case 'running':
            return 'bg-success'

        case 'deallocating':
        case 'deallocated':
            return 'bg-warning'

        case 'Error':
        case 'stopped':
        case 'stopping':
        case 'Failed':
            return 'bg-danger'

        case 'SendToCLi':
        case 'Unknown':
        case 'Creating':
            return 'bg-info'

        default:
            return ''
    }
}

export default setColor
