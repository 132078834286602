import React from 'react'
import 'bootstrap'
import { useAdminGet } from '@signpost/common/src/helpers/customHooks'
import { URLS } from '@signpost/common/src/helpers/api'
import { Status, Table } from 'components'
import { handleExtendSizesNames } from '../VMT/VMT'

const VMContainer = ({ id }) => {
    const { isLoaded: isLoadedVMs, error: dataVMsError, data: dataVMs } = useAdminGet(URLS.labs.virtualMachines(id))
    const { isLoaded: isLoadedSizes, error: sizesError, data: dataSizes } = useAdminGet(URLS.sizes)

    const isLoaded = isLoadedVMs && isLoadedSizes
    const error = dataVMsError || sizesError

    const extendedVirtualMachines = dataVMs?.map(vm => {
        return { ...vm, sizeName: handleExtendSizesNames(dataSizes, vm.sizeName) }
    })

    return (
        <Status isLoaded={isLoaded} error={error}>
            <Table
                title="Connected Virtual Machines"
                fetchStatus={{ isLoaded, error }}
                head={[
                    ['VM', 15],
                    ['State', 18],
                    ['OS', 15],
                    ['Size', 16],
                    ['Owner Email', 18],
                    ['Full Name', 18]
                ]}
                body={{
                    config: [
                        { key: 'name', idKey: 'name' },
                        { key: 'status', statusKey: 'status' },
                        { key: 'operatingSystem', osKey: 'operatingSystem' },
                        { key: 'sizeName' },
                        { key: 'owner' },
                        { key: 'ownerFullName' }
                    ],
                    data: extendedVirtualMachines
                }}
                className="vm-table"
                isBreakWord
            />
        </Status>
    )
}

export default VMContainer
