import React, { useState, useEffect } from 'react'
import { Input, Status } from 'components'
import { Table, SearchBar } from '@signpost/common/src/components'
import { adminGet, adminPut, adminPost, URLS } from '@signpost/common/src/helpers/api'
import { returnOnlyNotDuplicated } from '@signpost/common/src/helpers/object'

const head = [
    { name: 'Full Name', bodyKey: 'fullName', width: 40 },
    { name: 'Email', bodyKey: 'email', width: 60 }
]

const GroupsManage = ({ groupId, currentUsers, onSubmit }) => {
    const [users, setUsers] = useState([])
    const [chosenToAdd, setChosenToAdd] = useState([])
    const [isLoaded, setIsLoaded] = useState(true)
    const [error, setError] = useState(null)
    const [groupName, setGroupName] = useState(null)
    const [fieldErrors, setFieldErrors] = useState({})

    const validate = () => {
        const errors = {}
        if (!groupId && !groupName) {
            errors.groupName = 'Required field'
        } else if (!groupId && groupName.length < 3) {
            errors.groupName = 'Group name is too short. It should be at least 3 characters long.'
        }
        setFieldErrors({ ...errors })

        return Object.keys(errors).length === 0
    }

    const fetchData = async query => {
        if (query.length > 2) {
            setIsLoaded(false)
            try {
                const result = await adminGet({
                    url: URLS.schools.users,
                    query: {
                        page: 1,
                        perPage: 2222,
                        query
                    }
                })
                const allUsers = result.data.records
                setIsLoaded(true)
                setUsers(allUsers)
            } catch (error) {
                setError(error)
                setIsLoaded(true)
            }
        }
    }

    const onRowClick = row => {
        const chosenIds = chosenToAdd.map(el => el.azureId)
        if (chosenIds.includes(row.azureId)) {
            return null
        }
        setChosenToAdd([...chosenToAdd, row])
    }

    const onRemoveChosen = el => {
        const filteredChosenToAdd = chosenToAdd.filter(user => user.azureId !== el.azureId)
        setChosenToAdd(filteredChosenToAdd)
    }

    const onSubmitChosen = async chosen => {
        if (validate()) {
            const parsedChosen = chosen.map(({ azureId, tenantId }) => {
                return { azureId, tenantId }
            })
            const url = groupId ? URLS.groups.single(groupId) : URLS.groups.all
            const body = { students: parsedChosen }

            if (!groupId) {
                body.name = groupName
            }

            try {
                groupId ? await adminPut({ url, body }) : await adminPost({ url, body })
                onSubmit(true)
            } catch (err) {
                console.log('error', error)
                onSubmit(false)
            }
        }
    }

    const handleChangeGroupName = e => {
        setGroupName(e.target.value)
    }

    useEffect(() => {
        if (groupName !== null) {
            validate()
        }
    }, [groupName])

    return (
        <>
            {!groupId && (
                <div className="page-content">
                    <Input
                        label="Group name *"
                        name="groupName"
                        className="form-control-sm form-control"
                        value={groupName || ''}
                        onChange={handleChangeGroupName}
                        error={fieldErrors.groupName}
                    />
                </div>
            )}
            <SearchBar
                label="Find User By Full Name"
                placeholder="min. 3 characters"
                chosen={chosenToAdd}
                chosenNameKey="fullName"
                onRemoveChosen={onRemoveChosen}
                onChange={fetchData}
                onSubmitChosen={onSubmitChosen}
                submitButtonLabel={!groupId ? 'Create Group' : 'Add to Group'}
            />
            <Status isLoaded={isLoaded} error={error}>
                {users.length > 0 ? (
                    <Table
                        head={head}
                        body={
                            currentUsers
                                ? returnOnlyNotDuplicated(users, [...currentUsers, ...chosenToAdd], 'azureId')
                                : users
                        }
                        isBackground
                        onRowClick={onRowClick}
                    />
                ) : (
                    <div className="page-content">No users found</div>
                )}
            </Status>
        </>
    )
}

export default GroupsManage
