import React from 'react'
import { Link } from 'react-router-dom'
import 'styles/Labs.css'

const Listing = ({ data }) => (
    <div className="page-content labs bg-tr">
        {data?.map(({ id, name, teacher, isPublished, amountOfVirtualMachines, maxVirtualMachinesPerLab }) => (
            <Link key={id} className="labs-lab" to={`/labs/${id}`}>
                <div className="lab-ico-container">
                    <span className="lab-ico">
                        <i className="fas fa-flask" />
                    </span>
                </div>
                <h3 className="ellipses">{name}</h3>
                <span className="lab-teacher ellipses">{teacher}</span>
                <span className="lab-vm">
                    {isPublished ? amountOfVirtualMachines + '/' + maxVirtualMachinesPerLab : 'Not yet published'}
                </span>
            </Link>
        ))}
    </div>
)

export default Listing
