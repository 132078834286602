import React from 'react'
import { useAdminGet } from '@signpost/common/src/helpers/customHooks'
import { ContentHeader, Status } from 'components'
import Dashboard from './Dashboard'
import { URLS } from '@signpost/common/src/helpers/api'
import { useMeState } from 'modules/GlobalContext'

const DashboardContainer = () => {
    const { isLoaded, error, data } = useAdminGet(URLS.users.dashboard, {})
    const { data: meData } = useMeState()

    return (
        <>
            <ContentHeader title="Dashboard" />
            <Status isLoaded={isLoaded} error={error}>
                <Dashboard data={data} userRole={meData?.role} />
            </Status>
        </>
    )
}

export default DashboardContainer
