import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { ContentHeader, Status } from 'components'
import { Button, Confirm } from '@signpost/common/src/components'
import { Modal } from 'components/Modal'
import UsersListing from './UsersListing'
import { adminRemove, adminGet, URLS, API } from '@signpost/common/src/helpers/api'
import { handleShowNotification } from '@signpost/common/src/helpers/requestStatus'
import GroupsManage from '../GroupsManage'

const UsersListingContainer = () => {
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [data, setData] = useState({})
    const [isLoaded, setIsLoaded] = useState(false)
    const [isDeleteModal, setIsDeleteModal] = useState(false)
    const [isDeleteModalLoaded, setIsDeleteModalLoaded] = useState(true)
    const [error, setError] = useState(null)
    const [currentRow, setCurrentRow] = useState({})
    const [isAddToGroupOpen, setIsAddToGroupOpen] = useState(false)
    const history = useHistory()
    const buttonLabel = isAddToGroupOpen ? 'Hide Add To Group' : 'Show Add To Group'
    const groupId = history.location.pathname.split('/')[2]

    const handleChangePage = (_, newPage) => {
        setPage(newPage)
    }

    const fetchUsers = async () => {
        setIsLoaded(false)
        try {
            const result = await adminGet({
                url: URLS.groups.allStudents(groupId),
                query: {
                    page: page + 1,
                    perPage: rowsPerPage
                }
            })
            const groupData = result.data
            setData(groupData)
        } catch (error) {
            setError(error)
        } finally {
            setIsLoaded(true)
        }
    }

    useEffect(() => {
        fetchUsers()
    }, [page, rowsPerPage])

    const confirmDelete = async () => {
        setIsDeleteModalLoaded(false)
        const url = URLS.groups.byStudent(groupId, currentRow.id)

        try {
            const result = await adminRemove({ url })
            handleShowNotification(result && result.status)
            setIsDeleteModalLoaded(false)
            fetchUsers()
        } catch ({ response }) {
            handleShowNotification(response && response.status)
        } finally {
            setIsDeleteModalLoaded(true)
            toggleDeleteModal()
        }
    }

    const openAddingButtonStyles = {
        position: 'absolute',
        right: '40px',
        marginTop: '16px'
    }

    const toggleAddingToGroup = () => setIsAddToGroupOpen(!isAddToGroupOpen)

    const handleSubmitAdd = success => {
        if (success) {
            fetchUsers()
        }
        toggleAddingToGroup()
    }

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const toggleDeleteModal = (row, e) => {
        const tableButtonWasClicked = e && e.target && e.target.innerText === 'Delete'

        if (tableButtonWasClicked) {
            setIsDeleteModal(true)
            return setCurrentRow(row)
        }

        setIsDeleteModal(false)
        setCurrentRow({})
    }

    return (
        <>
            <ContentHeader title={`${data.name ? data.name : ''} Users`} />
            <Status isLoaded={isLoaded} error={error}>
                <Button text={buttonLabel} onClick={toggleAddingToGroup} style={openAddingButtonStyles} />
                <UsersListing
                    data={data.records}
                    handleOnRowClick={toggleDeleteModal}
                    paginationProps={{
                        page,
                        count: (data.meta && data.meta.total) || 0,
                        rowsPerPage,
                        onChangeRowsPerPage: handleChangeRowsPerPage,
                        onChangePage: handleChangePage
                    }}
                />
            </Status>
            {isDeleteModal && (
                <Modal>
                    <Confirm
                        isLoaded={isDeleteModalLoaded}
                        title="Delete User"
                        id={currentRow.id}
                        message={`Are you sure you want to delete user ${currentRow.email}? `}
                        confirmButtonText="Delete"
                        handleHide={toggleDeleteModal}
                        handleConfirm={confirmDelete}
                    />
                </Modal>
            )}
            {isAddToGroupOpen && (
                <GroupsManage groupId={groupId} currentUsers={data.records} onSubmit={handleSubmitAdd} />
            )}
        </>
    )
}

export default UsersListingContainer
