import React from 'react'
import { Form, Input } from 'components'
import styled from 'styled-components'
import 'styles/Settings.css'

const InputWrapper = styled.div`
    div {
        display: flex
        justify-content: space-between
    }
    label {
        margin-top: .5rem
    }
    input {
        max-width: 80px
    }
`

const Settings = ({ isLoaded, formData, handleSave, handleChange }) => {
    const {
        isAutoShutdownPolicyForCreditsLevelEnabled,
        creditsLevelForAutoShutdownPolicy,
        creditsLevelForAutoShutdownWarning,
        studentsMaxCreditsLevel,
        teachersMaxCreditsLevel
    } = formData

    const renderSwitch = () => (
        <p className="switch-wrapper">
            <label className="switch mr-2">
                <input
                    type="checkbox"
                    defaultChecked={isAutoShutdownPolicyForCreditsLevelEnabled}
                    onChange={handleChange}
                />
                <span className="slider round" />
            </label>
            <span>Force VM shutdown </span>
            Auto Shutdown For Credits Levels
        </p>
    )

    const renderInput = ({ label, value, name }) => (
        <InputWrapper>
            <Input
                label={label}
                type="number"
                value={value}
                placeholder={1}
                min="1"
                onChange={e => handleChange(e, name)}
            />
        </InputWrapper>
    )

    const formConfig = [
        { type: 'switch', value: isAutoShutdownPolicyForCreditsLevelEnabled },
        {
            isHidden: !isAutoShutdownPolicyForCreditsLevelEnabled,
            type: 'input',
            name: 'creditsLevelForAutoShutdownPolicy',
            value: creditsLevelForAutoShutdownPolicy,
            label: 'Credits Level For Auto Shutdown Policy'
        },
        {
            isHidden: !isAutoShutdownPolicyForCreditsLevelEnabled,
            type: 'input',
            name: 'creditsLevelForAutoShutdownWarning',
            value: creditsLevelForAutoShutdownWarning,
            label: 'Credits Level For AutoShut downWarning'
        },
        {
            type: 'input',
            name: 'studentsMaxCreditsLevel',
            value: studentsMaxCreditsLevel,
            label: 'Max Credits Level Per Student'
        },
        {
            type: 'input',
            name: 'teachersMaxCreditsLevel',
            value: teachersMaxCreditsLevel,
            label: 'Max Credits Level Per Teacher'
        }
    ]

    const renderFields = () =>
        formConfig.map(({ type, value, label, name, isHidden }) => {
            if (type === 'input') {
                return !isHidden && renderInput({ label, value, name })
            }
            return renderSwitch()
        })

    return (
        <div className="page-content">
            <p className="info">VM will be turned off after 15 mins of idle.</p>
            <Form isLoaded={isLoaded} handleSave={handleSave}>
                {renderFields()}
            </Form>
        </div>
    )
}

export default Settings
