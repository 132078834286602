import React, { useState, useEffect } from 'react'
import { ContentHeader, Status } from 'components'
import { SearchBar, LocalState, Checkbox } from '@signpost/common/src/components'
import Listing from './Listing'
import { adminGet, adminPost, URLS } from '@signpost/common/src/helpers/api'
import { handleShowNotification } from '@signpost/common/src/helpers/requestStatus'
import { Modal } from 'components/Modal'

const ListingContainer = () => {
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [query, setQuery] = useState('')
    const [data, setData] = useState({})
    const [isLoaded, setIsLoaded] = useState(false)
    const [isEditModalLoaded, setIsEditModalLoaded] = useState(true)
    const [error, setError] = useState(null)
    const [isEditModal, setIsEditModal] = useState(false)
    const [searchCheckboxes, setSearchCheckboxes] = useState({ admin: true, teacher: true, noRole: false })
    const [currentRow, setCurrentRow] = useState({})

    const handleChangePage = (_, newPage) => {
        setPage(newPage)
    }

    const fetchUsers = async () => {
        setIsLoaded(false)
        const url = searchCheckboxes.noRole ? URLS.users.students : URLS.users.teachers
        try {
            const result = await adminGet({
                url,
                query: {
                    page: page + 1,
                    perPage: rowsPerPage,
                    query,
                    isAdmin: searchCheckboxes.admin,
                    isTeacher: searchCheckboxes.teacher
                }
            })
            setData(result.data)
        } catch (error) {
            setError(error)
        } finally {
            setIsLoaded(true)
        }
    }

    const fetchUsersByQuery = async query => {
        if (query.length > 2 || query.length === 0) {
            setQuery(query)
        }
    }

    const handleSaveEdit = async values => {
        const url = URLS.users.all
        setIsEditModalLoaded(false)

        try {
            const result = await adminPost({
                url,
                body: {
                    azureId: currentRow.azureId,
                    tenantId: currentRow.tenantId,
                    isAdmin: values.isAdmin,
                    isTeacher: values.isTeacher
                }
            })
            handleShowNotification(result && result.status)
            fetchUsers()
            setIsEditModal(false)
        } catch (error) {
            handleShowNotification(error && error.status)
        } finally {
            setIsEditModalLoaded(true)
        }
    }

    useEffect(() => {
        fetchUsers()
    }, [page, rowsPerPage])

    useEffect(() => {
        if (searchCheckboxes.noRole) {
            if (query.length > 2) {
                fetchUsers()
            }
        }
        if (!searchCheckboxes.noRole) {
            if (!searchCheckboxes.admin && !searchCheckboxes.teacher) {
                return toggleCheckbox('noRole')
            }
            fetchUsers()
        }
    }, [searchCheckboxes, query])

    const toggleEditModal = (row, e) => {
        const tableButtonWasClicked = e && e.target && e.target.innerText === 'Edit'
        
        if (tableButtonWasClicked) {
            setIsEditModal(true)
            return setCurrentRow(row)
        }
        
        setIsEditModal(false)
        setCurrentRow({})
    }

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const toggleCheckbox = name => {
        if (name === 'noRole') {
            return setSearchCheckboxes({
                admin: searchCheckboxes[name],
                teacher: searchCheckboxes[name],
                noRole: !searchCheckboxes[name]
            })
        }
        setSearchCheckboxes({ ...searchCheckboxes, [name]: !searchCheckboxes[name], noRole: false })
    }

    const searchCheckboxesConfig = [
        {
            label: 'Admin',
            name: 'admin',
            isChecked: searchCheckboxes.admin,
            isDisabled: !isLoaded,
            handleChange: toggleCheckbox
        },
        {
            label: 'Teacher',
            name: 'teacher',
            isChecked: searchCheckboxes.teacher,
            isDisabled: !isLoaded,
            handleChange: toggleCheckbox
        },
        {
            label: 'No role',
            name: 'noRole',
            isChecked: searchCheckboxes.noRole,
            isDisabled: !isLoaded,
            handleChange: toggleCheckbox
        }
    ]

    const displayNoRoleInfo = searchCheckboxes.noRole && query.length < 3

    return (
        <>
            <ContentHeader title="Users" />
            <SearchBar
                label="User email starts with:"
                placeholder="min. 3 characters"
                checkboxes={searchCheckboxesConfig}
                onChange={fetchUsersByQuery}
            />
            {displayNoRoleInfo ? (
                <div className="page-content">
                    <i className="fas fa-arrow-up"></i> Use search
                </div>
            ) : (
                <Status isLoaded={isLoaded} error={error}>
                    <Listing
                        data={data.records}
                        handleOnRowClick={toggleEditModal}
                        paginationProps={{
                            page,
                            count: (data.meta && data.meta.total) || 0,
                            rowsPerPage,
                            onChangeRowsPerPage: handleChangeRowsPerPage,
                            onChangePage: handleChangePage
                        }}
                    />
                </Status>
            )}
            {isEditModal && (
                <Modal>
                    <LocalState
                        isLoaded={isEditModalLoaded}
                        title={'Edit roles'}
                        message={'User ' + currentRow.email}
                        componentsTypes={['checkbox', 'checkbox']}
                        initialValues={{ isAdmin: currentRow.isAdmin, isTeacher: currentRow.isTeacher }}
                        handleHide={toggleEditModal}
                        handleSave={handleSaveEdit}
                    >
                        <Checkbox name="isAdmin" label="Admin" />
                        <Checkbox name="isTeacher" label="Teacher" />
                    </LocalState>
                </Modal>
            )}
        </>
    )
}

export default ListingContainer
