import React from "react";
import { Field } from "formik";
import {
  InputLabel,
  FormControl,
  Switch as MaterialSwitch,
} from "@material-ui/core";
import "./Switch.css";

const Switch = ({ name, label }) => {
  return (
    <div>
      <FormControl variant="outlined" className="sc-switch-wrapper">
        <>
          {label && (
            <InputLabel id={name} className="sc-switch-label">
              {label}
            </InputLabel>
          )}
          <Field
            label={label}
            labelid={name}
            name={name}
            as={MaterialSwitch}
            className="sc-switch"
          ></Field>
        </>
      </FormControl>
    </div>
  );
};

export default Switch;
