import React, { useState } from 'react'
import 'bootstrap'
import { Input } from 'components'
import { Modal, Form } from 'components/Modal'

const SetNumberModal = ({ isLoaded = true, error, dataMax, title, inputLabel, handleHide, handleSave }) => {
    const [stateMax, setStateMax] = useState(null)

    const handleSetMax = e => {
        setStateMax(e.target.value)
    }
    
    return (
        <Modal>
            <Form
                isLoaded={isLoaded}
                error={error}
                title={title}
                handleHide={handleHide}
                handleSave={() => handleSave(stateMax)}
            >
                <Input
                    label={inputLabel}
                    name={stateMax}
                    type="number"
                    value={stateMax || dataMax}
                    placeholder={stateMax}
                    min='1'
                    onChange={handleSetMax}
                />
            </Form>
        </Modal>
    )
}

export default SetNumberModal
