import React, { Component } from 'react'
import 'bootstrap'
import { adminGet, adminPut, adminRemove, URLS } from '@signpost/common/src/helpers/api'
import { ContentHeader } from 'components'
import { Modal, Confirm } from 'components/Modal'
import Lab from './Lab'
import { successToast, errorToast } from '@signpost/common/src/helpers/requestStatus'
import { handleShowNotification } from '@signpost/common/src/helpers/requestStatus'

export default class LabContainer extends Component {
    state = {
        error: null,
        isLoaded: false,
        data: [],
        isModal: false,
        isModalLoaded: true,
        modalError: null
    }

    componentDidMount = () => {
        this.fetchLabData()
    }

    handlePublish = async () => {
        const { match } = this.props
        const { id } = match.params
        const url = URLS.labs.publish(id)

        try {
            const result = await adminPut({ url })
            await this.fetchLabData()
            successToast('The lab is published successfully!')
        } catch ({ response }) {
            errorToast('Publish failed!')
        }
    }

    handleToggleModal = () =>
        this.setState({
            isModal: !this.state.isModal
        })

    handleDeleteLab = async () => {
        const { match } = this.props
        const { id } = match.params
        const url = URLS.labs.single(id)

        this.setState({
            isModalLoaded: false
        })

        try {
            const result = await adminRemove({ url })
            this.props.history.push('/labs')
            handleShowNotification(result?.status)
        } catch ({ response }) {
            handleShowNotification(response?.status)
            this.setState({
                isModalLoaded: true
            })
        }
    }

    fetchLabData = async () => {
        const { match } = this.props
        const { id } = match.params
        const url = URLS.labs.single(id)

        try {
            const result = await adminGet({ url })

            this.setState({
                isLoaded: true,
                data: result.data
            })
        } catch (error) {
            this.setState({
                error
            })
        }
    }

    render() {
        const { isLoaded, data, error, isModal, isModalLoaded, modalError } = this.state
        const isPublished = data?.isPublished || typeof data?.isPublished === 'undefined'
        const headerButtons = [
            { isShown: !isPublished, buttonText: 'Publish', handleButtonClick: this.handlePublish },
            { isShown: true, buttonText: 'Delete', isDanger: true, handleButtonClick: this.handleToggleModal }
        ]

        return (
            <>
                <ContentHeader title="Lab" subtitle={data.id} buttons={headerButtons} />
                <Lab isLoaded={isLoaded} error={error} data={data} id={data.id} fetchLabData={this.fetchLabData} />
                {isModal && (
                    <Modal>
                        <Confirm
                            isLoaded={isModalLoaded}
                            error={modalError}
                            message="Are you sure you want to delete this lab?"
                            confirmButtonText="Delete"
                            handleHide={this.handleToggleModal}
                            handleConfirm={this.handleDeleteLab}
                        />
                    </Modal>
                )}
            </>
        )
    }
}
