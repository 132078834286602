import React from 'react'
import 'bootstrap'
import 'styles/Lab.css'
import Info from '../../components/Info'
// import VHDPanel from '../../components/VHDPanelContainer' temp disabled
import VM from '../../components/VM'
import VMT from '../../components/VMT'

const Lab = ({ isLoaded, data, id, fetchLabData }) => {
    if (!isLoaded) {
        return <div className="loader" />
    }

    return (
        <div className="container lab">
            <div className="row">
                <div className="col-12 col-md-5">
                    <Info id={id} data={data} fetchLabData={fetchLabData} />
                    {/* <VHDPanel id={id} />  temp disabled */}
                </div>
                <div className="col-12 col-md-7">
                    <VM id={id} />
                    <VMT id={id} isLoadingButton={!data?.virtualNetwork} />
                </div>
            </div>
        </div>
    )
}

export default Lab
