import React, { useState } from 'react'
import 'bootstrap'
import { adminPost, URLS } from '@signpost/common/src/helpers/api'
import { VMTModal as config, VMTModalKeys } from '@signpost/common/src/formsConfig'
import { Switch } from 'components'
import { Modal } from 'components/Modal'
import { Form, Input, Select } from '@signpost/common/src/components'
import { handleExtendSizeName } from './VMT'

const VMTModal = ({
    id,
    isLoaded,
    error,
    dataSizes,
    dataGallery,
    handleRefreshVMT,
    handleToggleModal
}) => {
    const [isPublicIPStatic, setIsPublicIPStatic] = useState(false)

    const handleTogglePublicIP = () => {
        setIsPublicIPStatic(!isPublicIPStatic)
    }

    const handleCreateTemplate = async data => {
        const url = URLS.templates.all
        const body = {
            ...data,
            labId: id,
            isPublicIPStatic
        }
        try {
            await adminPost({ url, body })
            handleToggleModal()
            handleRefreshVMT()
        } catch (err) {}
    }

    const galleryImages =
        dataGallery?.map(image => {
            return { name: image.name, id: image.id }
        })

    const sizes =
        dataSizes?.map(({name, coreCount, memoryCount, id}) => {
            return { name: handleExtendSizeName(name, coreCount, memoryCount), id }
        })

    const { initialValues, validationSchema } = config
    const { template, size, image } = VMTModalKeys

    return (
        <Modal>
            <Form
                isLoaded={isLoaded}
                error={error}
                title="Add Template"
                initialValues={initialValues}
                validationSchema={validationSchema}
                handleHide={handleToggleModal}
                handleSave={handleCreateTemplate}
                minHeight={480}
            >
                <Input label="Template Name" name={template} />
                <Select label="Size" name={size} values={sizes} />
                <Select label="Gallery Image" name={image} values={galleryImages} />
                <Switch name="isPublicIPStatic" label="Public IP is Static" onChange={handleTogglePublicIP} />
            </Form>
        </Modal>
    )
}

export default VMTModal
