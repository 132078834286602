import PropTypes from 'prop-types'
import React from 'react'
import { Button, SchoolSelect } from '@signpost/common/src/components'
import { useGlobalState } from 'modules/GlobalContext'

const ContentHeader = ({ title, subtitle, buttons }) => {
    const { isSupportAdmin, ...globalState } = useGlobalState()

    return (
        <div className="page-title">
            <h2>
                {title}
                {subtitle && <span className="page-sub-title"> - {subtitle}</span>}
            </h2>
            <div>
                {buttons
                    ?.filter(({ isShown }) => isShown)
                    ?.map(({ buttonText, isDanger, handleButtonClick }) => (
                        <Button key={buttonText} text={buttonText} isDanger={isDanger} onClick={handleButtonClick} />
                    ))}
                {isSupportAdmin && (
                    <SchoolSelect
                        defaultValue={globalState?.me?.data?.schoolId}
                        value={globalState.currentSchoolId}
                        setValue={globalState.setCurrentSchoolId}
                        schools={globalState?.schools?.data || []}
                        loading={!globalState?.schools?.isLoaded}
                    />
                )}
            </div>
        </div>
    )
}

ContentHeader.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            isShown: PropTypes.bool,
            buttonText: PropTypes.string,
            isDanger: PropTypes.bool,
            handleButtonClick: PropTypes.func
        })
    )
}

export default ContentHeader
