import React from 'react'
import { Button } from 'components'
import 'styles/Table.css'

const renderRowBody = body => {
    if (!body) {
        return '-'
    }
    
    const isArray = Array.isArray(body)
    const hasButton = !!body.button

    if (isArray) {
        return body.join(', ')
    }

    if (hasButton) {
        const { icon, onClick, className, isDisabled, isHidden } = body.button
        return (
            <>
                {body.value}
                <Button icon={icon} onClick={onClick} className={className} disabled={isDisabled} hidden={isHidden}/>
            </>
        )
    }

    return body
}

const TableVertical = ({ config }) => (
    <table className="table table-vertical">
        <tbody>
            {config.data.map(({ head, body }) => (
                <tr key={head}>
                    <th scope="row" style={{width: config.headWidth}}>{head}</th>
                    <td style={{width: config.bodyWidth}}>{renderRowBody(body)}</td>
                </tr>
            ))}
        </tbody>
    </table>
)

export default TableVertical
