import PropTypes from 'prop-types'
import React from 'react'
import { NavLink } from 'react-router-dom'
import 'styles/Sidebar.css'

const sidebarConfig = [
    { label: 'Dashboard', link: 'dashboard', icon: 'fab fa-slack-hash', hiddenForRoles: [] },
    {
        label: 'Messages',
        link: 'messages',
        icon: 'fas fa-comment-dots',
        isBorderBottom: true,
        hiddenForRoles: ['SupportAdmin']
    },
    { label: 'Labs', link: 'labs', icon: 'fas fa-flask', hiddenForRoles: [] },
    // { label: 'Virtual Machines', link: 'virtual-machines', icon: 'fas fa-server', hiddenForRoles: [] }, temp disabled
    { label: 'Virtual Networks', link: 'virtual-networks', icon: 'fas fa-network-wired', hiddenForRoles: [] },
    // { label: 'Custom VHDs', link: 'vhd', icon: 'fas fa-hdd', hiddenForRoles: [] }, temp disabled
    // { label: 'Scripts', link: 'scripts', icon: 'far fa-file-code', isBorderBottom: true, hiddenForRoles: [] }, temp disabled
    { label: 'Groups', link: 'groups', icon: 'fas fa-users', hiddenForRoles: [] },
    { label: 'Users', link: 'users', icon: 'fas fa-user-friends', hiddenForRoles: [] },
    // { label: 'Diagnostics', link: 'activity-logs', icon: 'fas fa-chart-line', hiddenForRoles: [] },
    { label: 'Cost Management', link: 'costs', icon: 'fas fa-wallet', hiddenForRoles: [] },
    { label: 'Settings', link: 'settings', icon: 'fas fa-sliders-h', isBorderBottom: true, hiddenForRoles: [] }
    // { label: 'Downloads', link: 'downloads', icon: 'fas fa-download', hiddenForRoles: [] }  temp disabled
]

const renderListElements = (config, userRole) =>
    config
        .filter(({ hiddenForRoles }) => !hiddenForRoles.includes(userRole))
        .map(({ label, link, icon, isBorderBottom }) => {
            return (
                <li key={label}>
                    <NavLink to={'/' + link} activeClassName="isCurrentLink">
                        <i className={icon} />
                        {label}
                    </NavLink>
                    {isBorderBottom && <hr />}
                </li>
            )
        })

const Sidebar = ({ handleLogout, userRole }) => (
    <nav className="main-sidebar">
        <div className="sidebar-header">
            <h3>Guide</h3>
        </div>
        <ul>
            {renderListElements(sidebarConfig, userRole)}
            <li>
                <button type="button" onClick={handleLogout} className="ico-red logout-button">
                    <i className="fas fa-power-off" />
                    Signout
                </button>
            </li>
        </ul>
    </nav>
)

Sidebar.propTypes = {
    handleLogout: PropTypes.func.isRequired,
    userRole: PropTypes.string
}

export default Sidebar
