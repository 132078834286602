import React, { useState } from 'react'
import 'bootstrap'
import { useAdminGet } from '@signpost/common/src/helpers/customHooks'
import { ContentHeader, SearchBar, Status } from 'components'
import Listing from './Listing'
import Modal from '../../components/Modal'
import { URLS } from '@signpost/common/src/helpers/api'

const ListingContainer = () => {
    const { isLoaded, error, data } = useAdminGet(URLS.virtualNetworks.all)
    const [filter, setFilter] = useState('')
    const [isModal, setIsModal] = useState(false)

    const handleSetFilter = e => {
        setFilter(e.target.value)
    }

    const handleToggleModal = action => {
        setIsModal(action === 'show')
    }

    const filteredData = data?.filter(({ name }) => name.toLowerCase().includes(filter.toLowerCase()))

    return (
        <>
            <ContentHeader title="Virtual Networks" />
            <SearchBar
                value={filter}
                onChange={handleSetFilter}
                placeholder="Find your virtual network"
                buttonText=""
                onButtonClick={() => handleToggleModal('show')}
            />
            <Status isLoaded={isLoaded} error={error}>
                <Listing data={filteredData} />
            </Status>
            {isModal && <Modal handleToggleModal={handleToggleModal} />}
        </>
    )
}

export default ListingContainer
