import React from 'react'
import 'bootstrap'
import { TableVertical } from 'components'

const InfoContainer = ({
    data = {},
    handleToggleNameModal,
    handleToggleMaxVMsInLabModal,
    handleToggleMaxVMsPerUserModal
}) => {
    const {
        name,
        virtualNetwork,
        maxVirtualMachinesPerLab,
        maxVirtualMachinesPerStudent,
        teachers,
        groupsNames,
        subnetName,
        regionName,
        hasIPv6AddressSpace
    } = data

    const changeStructureTeachers = teachers?.map(({ email, fullName }) => `${email} (${fullName})`)
    const config = {
        headWidth: '40%',
        bodyWidth: '60%',
        data: [
            {
                head: 'Name',
                body: {
                    value: name,
                    button: {
                        icon: 'fas fa-cog',
                        onClick: handleToggleNameModal,
                        className: 'btn btn-action btn-sm isRight'
                    }
                }
            },
            { head: 'Teacher', body: changeStructureTeachers },
            { head: 'Groups', body: groupsNames },
            {
                head: 'Max VMs in Lab',
                body: {
                    value: maxVirtualMachinesPerLab,
                    button: {
                        icon: 'fas fa-cog',
                        onClick: handleToggleMaxVMsInLabModal,
                        className: 'btn btn-action btn-sm isRight'
                    }
                }
            },
            {
                head: 'Max VMs per User',
                body: {
                    value: maxVirtualMachinesPerStudent,
                    button: {
                        icon: 'fas fa-cog',
                        onClick: handleToggleMaxVMsPerUserModal,
                        className: 'btn btn-action btn-sm isRight'
                    }
                }
            },
            { head: 'Virtual Network', body: virtualNetwork },
            { head: 'Subnet', body: subnetName },
            { head: 'Region', body: regionName },
            { head: 'Has IPv6 Address Space', body: hasIPv6AddressSpace ? 'Yes' : 'No' }
        ]
    }

    return (
        <div className="page-content">
            <h3>Lab Info</h3>
            <TableVertical config={config} />
        </div>
    )
}

export default InfoContainer
