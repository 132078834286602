import React from 'react'
import { adminGet, URLS } from '@signpost/common/src/helpers/api'
import { ContentHeader } from 'components'
import Listing from './Listing'

export default class CostsPage extends React.Component {
    state = {
        isLoaded: true,
        error: null,
        data: { student: [], lab: [], teacher: [] },
        isUpdatedData: { student: false, lab: false, teacher: false },
        isFilterButtonActive: false,
        activeTabName: 'students',
        dateRange: {
            startDate: null,
            endDate: null
        }
    }

    handleGetTransactions = async () => {
        this.setState({
            isLoaded: false,
            isFilterButtonActive: false
        })

        const { activeTabName, data, isUpdatedData, dateRange } = this.state
        const { startDate, endDate } = dateRange
        const url = URLS.transactions[activeTabName]
        const query = endDate ? { startDate: startDate.toISOString(), endDate: endDate.toISOString() } : ''

        try {
            const result = await adminGet({
                url,
                query
            })
            this.setState({
                isLoaded: true,
                data: { ...data, [activeTabName]: result.data },
                isUpdatedData: { ...isUpdatedData, [activeTabName]: true }
            })
        } catch (error) {
            this.setState({
                isLoaded: true,
                error
            })
        }
    }

    componentDidMount = () => {
        this.handleGetTransactions()
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { activeTabName, isUpdatedData } = this.state
        const hasClickedNotActiveTab = prevState.activeTabName !== activeTabName
        const activeTabDataShouldBeUpdated = !isUpdatedData[activeTabName]

        if (hasClickedNotActiveTab && activeTabDataShouldBeUpdated) {
            this.handleGetTransactions()
        }
    }

    handleChooseTab = (event, activeTabName) => {
        this.setState({ activeTabName })
    }

    handleSelectDateRange = dateRange => {
        const range = dateRange && dateRange.range1
        const isReset = dateRange === 'reset'
        this.setState({
            dateRange: {
                startDate: isReset ? null : new Date(range.startDate),
                endDate: isReset ? null : new Date(range.endDate)
            },
            isUpdatedData: { student: false, lab: false, teacher: false },
            isFilterButtonActive: true
        })
    }

    render() {
        const { data, isLoaded, isFilterButtonActive, error, activeTabName } = this.state

        return (
            <>
                <ContentHeader title="Cost Management" />
                <Listing
                    data={data}
                    isLoaded={isLoaded}
                    isFilterButtonActive={isFilterButtonActive}
                    error={error}
                    activeTabName={activeTabName}
                    handleChooseTab={this.handleChooseTab}
                    handleRefreshTable={this.handleGetTransactions}
                    handleSelectDateRange={this.handleSelectDateRange}
                />
            </>
        )
    }
}
