import { useRef, useState, useEffect } from "react";

const OutsideClick = ({ render }) => {
  const node = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleIsOpen = () => setIsOpen(!isOpen);

  const handleClick = e => {
    const isOutsideClick = node.current && !node.current.contains(e.target);

    if (isOutsideClick) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  return render({ node, isOpen, handleToggleIsOpen });
};

export default OutsideClick;
