import React from 'react'

const Layout = ({ isLoaded, error, title, children, minHeight, handleHide }) => (
    <div className="modal-wrapper">
        <div className="background" onClick={handleHide} />
        <div className="modal-dialog" role="document">
            <div className="modal-content" style={{ minHeight }}>
                <div className="modal-header">
                    {title && <h5 className="modal-title">{title}</h5>}
                    <button type="button" className="close" onClick={handleHide}>
                        &times;
                    </button>
                </div>
                {!error && isLoaded ? children : <div className="loader" />}
                {error && <div>Error: {error.data && error.data.Message}</div>}
            </div>
        </div>
    </div>
)

export default Layout
