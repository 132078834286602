import React from 'react'

const Input = ({
    label,
    name,
    type = 'text',
    id,
    error,
    className = 'form-control',
    placeholder,
    value,
    min,
    isRequired,
    onChange,
    onClick,
    secondLabel
}) => (
    <div className="form-group">
        {label && <label htmlFor={name}>{label}</label>}
        <input
            type={type}
            id={id || name}
            className={className}
            placeholder={placeholder}
            value={value}
            min={min}
            required={isRequired}
            onChange={e => onChange && onChange(e, name)}
            onClick={onClick}
        />
        {secondLabel && <label htmlFor={name}>{secondLabel}</label>}
        {error && <div className="error">{error}</div>}
    </div>
)

export default Input
