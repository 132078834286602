import React from 'react'
import { Table } from '@signpost/common/src/components'

const head = [
    { name: 'Email', bodyKey: 'email', width: 40 },
    { name: 'Full Name', bodyKey: 'fullName', width: 40 },
    { name: '', bodyKey: 'customButton', width: 20, text: 'Delete', isDanger: true }
]

const UsersListing = ({ data, paginationProps, handleOnRowClick }) => (
    <Table head={head} body={data} paginationProps={paginationProps} isBackground onRowClick={handleOnRowClick} />
)

export default UsersListing
