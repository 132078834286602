import React from 'react'
import { Button } from "@signpost/common/src/components";

import Layout from './Layout'

const Confirm = ({
    isLoaded,
    error,
    title,
    message,
    children,
    oneButtonText,
    confirmButtonText = 'Delete',
    minHeight,
    handleHide,
    handleConfirm
}) => (
    <Layout isLoaded={isLoaded} error={error} title={title} minHeight={minHeight} handleHide={handleHide}>
        <div className="sc-modal-message">{message}</div>
        {children && <div className="sc-modal-message">{children}</div>}
        <div className="sc-modal-footer">
            <Button text={oneButtonText || 'Cancel'} isDark onClick={handleHide} />
            {!oneButtonText && <Button text={confirmButtonText} isDanger onClick={handleConfirm} />}
        </div>
    </Layout>
)

export default Confirm
