import React from 'react'
import { Button } from 'components'

const Form = ({ children, isLoaded, isDisabled, handleSave }) => {
    const onSave = e => {
        e.preventDefault()
        handleSave()
    }

    return (
        <form>
            <div className="modal-body">{children}</div>
            <div className="modal-footer">
                <Button
                    className="btn-action"
                    text="Save"
                    onClick={onSave}
                    isLoading={!isLoaded}
                    disabled={!isLoaded || isDisabled}
                />
            </div>
        </form>
    )
}

export default Form
