import React, { useState, useEffect } from 'react'
import 'bootstrap'
import { URLS, adminGet } from '@signpost/common/src/helpers/api'
import { useAdminGet } from '@signpost/common/src/helpers/customHooks'
import VMT from './VMT'
import Modal from './VMTModal'

const VMTContainer = ({ id, isLoadingButton }) => {
    const [isModal, setIsModal] = useState(false)
    const [templates, setTemplates] = useState({ isTemplateLoaded: false, templateError: null, dataTemplate: [] })
    const { isTemplateLoaded, templateError, dataTemplate } = templates
    const { isLoaded: isLoadedSizes, error: sizesError, data: dataSizes } = useAdminGet(URLS.sizes)
    const { isLoaded: isLoadedGalleryImages, error: galleryImagesError, data: dataGallery } = useAdminGet(
        URLS.galleryImages.all
    )

    const isModalFormLoaded = isLoadedSizes && isLoadedGalleryImages
    const displayError = sizesError || galleryImagesError

    const handleToggleModal = action => {
        setIsModal(action === 'show')
    }

    const handleGetVMT = async () => {
        const url = URLS.labs.templates(id)

        try {
            const result = await adminGet({ url })
            setTemplates({ ...templates, isTemplateLoaded: true, dataTemplate: result.data })
        } catch (err) {
            setTemplates({ ...templates, templateError: err })
        }
    }

    useEffect(() => {
        handleGetVMT()
    }, [])

    return (
        <>
            <VMT
                data={dataTemplate}
                fetchStatus={{ isTemplateLoaded, templateError }}
                dataSizes={dataSizes}
                isLoadingButton={isLoadingButton}
                handleToggleModal={handleToggleModal}
            />
            {isModal && (
                <Modal
                    id={id}
                    isLoaded={isModalFormLoaded}
                    error={displayError}
                    dataSizes={dataSizes}
                    dataGallery={dataGallery}
                    handleRefreshVMT={handleGetVMT}
                    handleToggleModal={handleToggleModal}
                />
            )}
        </>
    )
}

export default VMTContainer
