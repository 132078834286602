import React, { useState } from 'react'
import 'bootstrap'
import Info from './Info'
import { Modal } from 'components/Modal'
import { Form, Input } from '@signpost/common/src/components'
import { SetNumberModal } from '@signpost/common/src/components/Modal'
import { labName as config, labNameKeys } from '@signpost/common/src/formsConfig'
import { adminPut, URLS } from '@signpost/common/src/helpers/api'
import { errorToast, successToast } from '@signpost/common/src/helpers/requestStatus'

const InfoContainer = ({ id, data, fetchLabData }) => {
    const [isNameModal, setIsNameModal] = useState(false)
    const [isMaxVMsInLabModal, setIsMaxVMsInLabModal] = useState(false)
    const [isMaxVMsPerUserModal, setIsMaxVMsPerUserModal] = useState(false)
    const [isLoaded, setIsLoaded] = useState(true)

    const handleToggleNameModal = () => {
        setIsNameModal(!isNameModal)
    }

    const handleToggleMaxVMsInLabModal = () => {
        setIsMaxVMsInLabModal(!isMaxVMsInLabModal)
    }

    const handleToggleMaxVMsPerUserModal = () => {
        setIsMaxVMsPerUserModal(!isMaxVMsPerUserModal)
    }

    const handleSubmitName = value => {
        updateLab(URLS.labs.labName(id), handleToggleNameModal, value)
    }

    const handleSubmitMaxVMsInLab = value => {
        updateLab(URLS.labs.vmsPerLab(id, value), handleToggleMaxVMsInLabModal)
    }

    const handleSubmitMaxVMsPerStudent = value => {
        updateLab(URLS.labs.vmsPerStudent(id, value), handleToggleMaxVMsPerUserModal)
    }

    const updateLab = async (url, handleToggle, body) => {
        setIsLoaded(false)
        try {
            await adminPut({ url, body })
            await fetchLabData()
            handleToggle()
            successToast('The lab is updated successfully!')
        } catch (exception) {
            errorToast('Update failed!')
        } finally {
            setIsLoaded(true)
        }
    }

    const { initialValues, validationSchema } = config

    return (
        <>
            <Info
                data={data}
                handleToggleNameModal={handleToggleNameModal}
                handleToggleMaxVMsInLabModal={handleToggleMaxVMsInLabModal}
                handleToggleMaxVMsPerUserModal={handleToggleMaxVMsPerUserModal}
            />
            {isNameModal && (
                <Modal>
                    <Form
                        isLoaded={isLoaded}
                        title="Set New Lab Name"
                        initialValues={{ name: data.name } || initialValues}
                        validationSchema={validationSchema}
                        handleHide={handleToggleNameModal}
                        handleSave={handleSubmitName}
                    >
                        <Input label="Name" name={labNameKeys.name} />
                    </Form>
                </Modal>
            )}
            {isMaxVMsInLabModal && (
                <SetNumberModal
                    dataMax={data.maxVirtualMachinesPerLab}
                    title="Set Max VMs in Lab"
                    inputLabel="Max VMs"
                    isLoaded={isLoaded}
                    handleHide={handleToggleMaxVMsInLabModal}
                    handleSave={handleSubmitMaxVMsInLab}
                />
            )}
            {isMaxVMsPerUserModal && (
                <SetNumberModal
                    dataMax={data.maxVirtualMachinesPerStudent}
                    title="Set Max VMs Per User"
                    inputLabel="Max VMs"
                    isLoaded={isLoaded}
                    handleHide={handleToggleMaxVMsPerUserModal}
                    handleSave={handleSubmitMaxVMsPerStudent}
                />
            )}
        </>
    )
}

export default InfoContainer
