import React from 'react'
import { Table } from 'components'

const VirtualNetwork = ({ error, isLoaded, item }) => {
    if (error) {
        return <div>Error: {error.message}</div>
    }

    if (!isLoaded) {
        return <div className="loader" />
    }

    const fistTableMockedBody = [
        {
            Subnetworks: item.Subnetworks[0],
            AddressRange: 'TODO',
            AvailableAddresses: 'TODO'
        }
    ]
    const secondTableMockedBody = [
        {
            Device: 'VM001',
            NetworkInterface: 'Network interface',
            Ip: '192.168.0.100',
            Subnet: 'Subnet001'
        },
        {
            Device: 'VM002',
            NetworkInterface: 'Network interface',
            Ip: '192.168.0.100',
            Subnet: 'Subnet001'
        }
    ]

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 col-xl-5">
                    <Table
                        title="Subnets"
                        button={{
                            text: 'Create',
                            className: 'btn-action btn-sm',
                            icon: 'fas fa-plus',
                            onClick: () => {}
                        }}
                        head={[['Name', 20], ['Address Range', 40], ['Available Addresses', 40]]}
                        body={{
                            config: [{ key: 'Subnetworks' }, { key: 'AddressRange' }, { key: 'AvailableAddresses' }],
                            data: fistTableMockedBody
                        }}
                    />
                </div>
                <div className="col-12 col-xl-7">
                    <Table
                        title="Connected Devices"
                        head={[['Device', 20], ['Type', 40], ['IP Address', 20], ['Subnet', 20]]}
                        body={{
                            config: [{ key: 'Device' }, { key: 'NetworkInterface' }, { key: 'Ip' }, { key: 'Subnet' }],
                            data: secondTableMockedBody
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default VirtualNetwork
