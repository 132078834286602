import React, { useState, useEffect } from 'react'
import 'bootstrap'
import { adminGet, adminPost, URLS } from '@signpost/common/src/helpers/api'
import { successToast, errorToast } from '@signpost/common/src/helpers/requestStatus'
import { Modal } from 'components/Modal'
import { Form, Input, Select, Switch } from '@signpost/common/src/components'
import { createLabModal as config } from '@signpost/common/src/formsConfig'

const CreateLabModal = ({ isLoaded, error, dataGroups, dataRegions, dataVirtualNetworks, handleToggleModal, handleRefreshData }) => {
    const [allTeachers, setAllTeachers] = useState([])
    const fetchData = async () => {
        const result = await adminGet({ url: URLS.teachers })
        const teachers = result?.data?.map(({ id, email }) => ({ id, name: email }))
        setAllTeachers(teachers)
    }

    useEffect(() => {
        fetchData()
    }, [])

    const refreshAndClose = () => {
        handleRefreshData()
        handleToggleModal()
    }
    const handleCreateLab = async data => {
        successToast('Lab creation started!')
        setTimeout(() => refreshAndClose(), 1500)
        const url = URLS.labs.all
        const body = data

        try {
            await adminPost({ url, body })
        } catch (err) {
            errorToast('Sending failed')
            return Promise.reject('Sending failed')
        }
    }

    const groups = dataGroups?.map(({ id, name }) => ({ id, name }))

    const { initialValues, validationSchema } = config

    return (
        <Modal>
            <Form
                isLoaded={isLoaded}
                error={error}
                title="Create New Lab"
                initialValues={initialValues}
                validationSchema={validationSchema}
                minHeight={691}
                handleHide={handleToggleModal}
                handleSave={handleCreateLab}
            >
                <Input label="Lab Name:*" name="name" />
                <Input label="New Virtual Network will be created" name="virtualNetworkId" isDisabled /> 
                {/* <Select     temp changed virtualNetworkId Select to Input
                    label="Create new Virtual Network or select from existing:"
                    name="virtualNetworkId"
                    values={dataVirtualNetworks}
                /> */}
                <Select label="Select Teacher:" name="teachersIds" values={allTeachers} isMultipleChoice />
                <Select label="Select Groups:*" name="groupsIds" values={groups} isMultipleChoice />
                <Select label="Select Region:*" name="regionId" values={dataRegions} />
                <Input label="Max VMs Per Student:" name="maxVirtualMachinesPerStudent" type="number" />
                <Input label="Max Total VMs Allowed:" name="maxVirtualMachinesPerLab" type="number" />
                <Switch label="IPv6 Address Space:" name="hasIPv6AddressSpace" />
            </Form>
        </Modal>
    )
}

export default CreateLabModal
