import React from 'react'
import { Table } from '@signpost/common/src/components'

const head = [
    { name: 'Email', bodyKey: 'email', width: 30 },
    { name: 'Full Name', bodyKey: 'fullName', width: 30 },
    { name: 'Admin', bodyKey: 'isAdmin', width: 10 },
    { name: 'Teacher', bodyKey: 'isTeacher', width: 10 },
    { name: '', bodyKey: 'customButton', width: 20, text: 'Edit' }
]

const UsersListing = ({ data, paginationProps, handleOnRowClick }) => {
    return (
        <Table head={head} body={data} isBackground paginationProps={paginationProps} onRowClick={handleOnRowClick} />
    )
}

export default UsersListing
