import React, { useState } from 'react'
import 'bootstrap'
import { Input } from 'components'
import { Modal, Form } from 'components/Modal'

const defaultFormValues = {
    name: '',
    addressSpace: '',
    subnetName: '',
    subnetAddressSpace: ''
}

const VMModal = ({ handleToggleModal }) => {
    const [formValues, setFormValues] = useState(defaultFormValues)
    const [isLoaded, setIsLoaded] = useState(true)
    const [error, setError] = useState(null)

    const handleChange = (e, name) => {
        const { value } = e.target
        setFormValues({ ...formValues, [name]: value })
    }

    const handleCreateTemplate = () => {
        // send request and then
        setIsLoaded()
        setError()
    }

    const { name, addressSpace, subnetName, subnetAddressSpace } = formValues
    return (
        <Modal>
            <Form
                isLoaded={isLoaded}
                error={error}
                title="Create New Virtual Network"
                handleHide={handleToggleModal}
                handleSave={handleCreateTemplate}
            >
                <Input label="Name:" name="name" value={name} onChange={handleChange} />
                <Input
                    label="Address space:"
                    secondLabel="10.2.0.0 - 10.2.255.255 (65536 addresses)"
                    name="addressSpace"
                    placeholder="10.2.0.0/16"
                    value={addressSpace}
                    onChange={handleChange}
                />
                <hr />
                <Input label="Subnet name:" name="subnetName" value={subnetName} onChange={handleChange} />
                <Input
                    label=" Subnet address space:"
                    secondLabel="10.2.0.0 - 10.2.0.255 (256 addresses)"
                    name="subnetAddressSpace"
                    placeholder="10.2.0.0/24"
                    value={subnetAddressSpace}
                    onChange={handleChange}
                />
            </Form>
        </Modal>
    )
}
export default VMModal
