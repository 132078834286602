import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { Button, Status } from 'components'
import setColor from '@signpost/common/src/helpers/color'

import 'styles/Table.css'

const renderHead = head => (
    <thead>
        <tr>
            {head.map(col => {
                return (
                    <th key={col[0]} scope="col" style={{ width: col[1] + '%' }}>
                        {col[0]}
                        {col[2] && <span className='subname'>{col[2]}</span>}
                    </th>
                )
            })}
        </tr>
    </thead>
)

const renderBodyTd = (bodyConfig, row, index, expandedId, isDisabled) => {
    const rowKeys = bodyConfig.map(el => el.key)

    return rowKeys.map((key, keyIndex) => {
        const { link, idKey, statusKey, osKey, dateFormat, isFirstLetterIcon, hiddenValueKey } = bodyConfig[keyIndex]

        if (link) {
            return (
                <td key={key}>
                    <Link className="btn-link" to={link + row[idKey]}>
                        {isFirstLetterIcon && <span className="img-ico">{row[key].charAt(0).toUpperCase()}</span>}
                        <p key={key}>{row[key]}</p>
                    </Link>
                </td>
            )
        }

        if (statusKey) {
            const status = (row[statusKey] || 'Unknown').replace('PowerState/', '')
            return (
                <td key={key}>
                    <span className={`activity-status ${setColor(status)}`}>{status}</span>
                </td>
            )
        }

        if (osKey) {
            const os = row[osKey] || 'Unknown'
            return (
                <td key={key}>
                    <span className="os-icon">
                        <i className={`fab fa-${os.toLowerCase()}`} /> {os}
                    </span>
                </td>
            )
        }

        if (hiddenValueKey) {
            return (
                <td key={key}>
                    <p className="with-hidden"> {row[key]}</p>
                    <div className={`hidden-content${index === expandedId ? ' isExpanded' : ''}`}>
                        {row[hiddenValueKey]}
                    </div>
                </td>
            )
        }

        if (key === 'customKey') {
            const { buttons } = bodyConfig[keyIndex]
            return (
                <td key="customKey">
                    {buttons &&
                        !isDisabled &&
                        buttons.map(({ text, icon, className, onClick }) => {
                            return (
                                <Button
                                    key={text}
                                    text={text}
                                    icon={icon}
                                    className={className}
                                    onClick={e => {
                                        e.stopPropagation()
                                        onClick(row)
                                    }}
                                />
                            )
                        })}
                </td>
            )
        }

        return <td key={key}>{dateFormat ? moment(row[key]).format(dateFormat) : row[key]}</td>
    })
}

const renderBody = (body, disableKey, bodyTrClassName, expandedId, handleToggleExpanded) => {
    const objectWithKey = key => body.config.find(el => el[key])
    const objectWithHiddenValue = objectWithKey('hiddenValueKey')
    const hasHiddenValueKey = !!objectWithHiddenValue
    const hiddenValueKey = hasHiddenValueKey && objectWithHiddenValue.hiddenValueKey

    return (
        <tbody>
            {body &&
                body.data &&
                body.data.map((row, index) => {
                    const isDisabled = row[disableKey]
                    const hasHiddenValue = hasHiddenValueKey && row[hiddenValueKey]
                    return (
                        <tr
                            key={index}
                            className={`${bodyTrClassName}${isDisabled ? ' isDisabled' : ''}${
                                hasHiddenValue ? ' hasHiddenValue' : ''
                            } `}
                            onClick={() => handleToggleExpanded(index)}
                        >
                            {renderBodyTd(body.config, row, index, expandedId, isDisabled)}
                        </tr>
                    )
                })}
        </tbody>
    )
}

const TableContainer = ({
    title,
    button = {},
    head,
    body,
    fetchStatus,
    className = '',
    bodyTrClassName = '',
    isBreakWord,
    disableKey
}) => {
    const [expandedId, setExpandedId] = useState(null)
    const { text, icon, onClick } = button
    const colsWidthSum = head.reduce((acc, col) => acc + col[1], 0)

    const handleToggleExpanded = index => {
        setExpandedId(expandedId === index ? null : index)
    }

    return (
        <div className={`page-content ${className}`}>
            {colsWidthSum !== 100 && 'Columns width sum is ' + colsWidthSum + ', should be 100'}
            <div className="base-table-header">
                <h3>{title}</h3>
                {(text || icon) && <Button text={text} icon={icon} onClick={onClick} />}
            </div>
            <Status isLoaded={(fetchStatus && fetchStatus.isLoaded) || true} error={(fetchStatus && fetchStatus.error) || null}>
                <table className={`base-table${isBreakWord ? ' break-word' : ''}`}>
                    {renderHead(head)}
                    {renderBody(body, disableKey, bodyTrClassName, expandedId, handleToggleExpanded)}
                </table>
            </Status>
        </div>
    )
}

export default TableContainer
