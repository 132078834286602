import React from 'react'
import styled from 'styled-components'
import { useMeState } from 'modules/GlobalContext'

import 'styles/Header.css'

const UserNameDiv = styled.div`
    color: #fff;
    align-self: center;
    font-size: 1.2em;
    position: absolute;
    right: 12px;
`

const handleToggleSideBar = () => {
    document.getElementById('root').classList.toggle('active')
}
const Header = () => {
    const me = useMeState()

    return (
        <header className="main-header">
            <button type="button" className="navbar-btn main-header-button" onClick={handleToggleSideBar}>
                <span />
                <span />
                <span />
            </button>
            <span>Student Cloud</span>
            <UserNameDiv>{me?.data?.email || ''}</UserNameDiv>
        </header>
    )

}
export default Header
