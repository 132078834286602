import React from 'react'

const NoMatch = () => (
    <div className="no-match">
        <div>
            <h2>
                <span>
                    <i className="fas fa-ethernet" />
                </span>{' '}
                Oops...
                <br />
            </h2>
            <p>Page cannot be found</p>
        </div>
    </div>
)

export default NoMatch
