import React from 'react'
import Layout from './MainLayout'
import { Button } from 'components'

const Confirm = ({
    isLoaded,
    error,
    title,
    message,
    confirmButtonText = 'Delete',
    minHeight,
    handleHide,
    handleConfirm
}) => (
    <Layout isLoaded={isLoaded} error={error} title={title} minHeight={minHeight} handleHide={handleHide}>
        <div className="modal-body">{message}</div>
        <div className="modal-footer">
            <Button className="btn-dark" text="Cancel" onClick={handleHide} />
            <Button className="btn-danger" text={confirmButtonText} onClick={handleConfirm} />
        </div>
    </Layout>
)

export default Confirm
