import React from 'react'
import { Button } from 'components'
import Layout from './MainLayout'

const Form = ({ isLoaded, error, title, children, minHeight, handleHide, handleSave, isSaveDisabled, isCancelDisabled }) => (
    <Layout isLoaded={isLoaded} error={error} title={title} minHeight={minHeight} handleHide={handleHide}>
        <form>
            <div className="modal-body">{children}</div>
            <div className="modal-footer">
                <Button className="btn-dark" text="Cancel" onClick={handleHide} disabled={isCancelDisabled} />
                <Button className="btn-action" text="Save" onClick={handleSave} disabled={isSaveDisabled} />
            </div>
        </form>
    </Layout>
)

export default Form
