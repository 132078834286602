import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'

import { Dashboard } from 'modules/Dashboard/pages'
// import { Listing as DiagnosticsListing } from 'modules/Diagnostics/pages'
import { Listing as MessagesListing } from 'modules/Messages/pages'
import { Listing as LabsListing, Lab } from 'modules/Labs/pages'
// import { Listing as VirtualMachinesListing, VirtualMachine } from 'modules/VirtualMachines/pages' temp disabled
import { Listing as VirtualNetworksListing, VirtualNetwork } from 'modules/VirtualNetworks/pages'
// import { Listing as ScriptsListing, Script } from 'modules/Scripts/pages' temp disabled
// import { Listing as VhdListing } from 'modules/Vhd/pages' temp disabled
import { GroupsListing, UsersListing as GroupUsersListing } from 'modules/Groups/pages'
import { GlobalContext } from 'modules/GlobalContext'
import { useMe } from 'modules/Me'
import { useSchools } from 'modules/Schools'
import { Listing as UsersListing } from 'modules/Users/pages'
// import { Listing as UsersListing, User } from 'modules/Users/pages' temp disabled
import { Listing as CostsListing } from 'modules/Costs/pages'
import { Settings } from 'modules/Settings/pages'
// import { Downloads } from 'modules/Downloads/pages' temp disabled
import { Notification } from '@signpost/common/src/components'
import { Login, Header, Sidebar, Start, Footer, NoMatch, Status } from './components'

import '../../../node_modules/@fortawesome/fontawesome-free/css/all.min.css'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../../../node_modules/daterangepicker/daterangepicker.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import './App.css'
import './styles/Loading.css'

let Listener = () => {}
window.authorization = {
    isAuthorized: true,
    set status(val) {
        this.isAuthorized = val
        Listener(val)
    },
    get status() {
        return this.isAuthorized
    },
    registerListener: listener => {
        Listener = listener
    }
}

const App = ({ authenticationState, login, logout }) => {
    const [isAuthorized, setIsAuthorized] = useState(true)
    const isLoggedIn = authenticationState === 'Authenticated'
    const { meData, isMeLoaded, meError, fetchMe } = useMe()
    const { schoolsData, areSchoolsLoaded, schoolsError, fetchSchools } = useSchools()

    const defaultSchoolId = meData?.schoolId
    const [currentSchoolId, setCurrentSchoolId] = useState(() => localStorage.getItem('schoolId') || defaultSchoolId)
    const userRole = meData?.role
    const isLoggedInAndAuthorized = isLoggedIn && isAuthorized

    useEffect(() => {
        if  (isLoggedInAndAuthorized) {
            fetchMe(setCurrentSchoolId)
        }
    }, [isLoggedInAndAuthorized])

    useEffect(() => {
        if  (userRole === 'SupportAdmin') {
            fetchSchools()
        }
    }, [userRole])

    useEffect(() => {
        if  (!localStorage.getItem('schoolId') && defaultSchoolId) {
            setCurrentSchoolId(defaultSchoolId)
        }
    }, [defaultSchoolId])

    window.authorization.registerListener(() => setIsAuthorized(false))

    return (
        <GlobalContext.Provider
            value={{
                me: {
                    data: meData,
                    isLoaded: isMeLoaded,
                    error: meError,
                },
                schools: {
                    data: schoolsData,
                    isLoaded: areSchoolsLoaded,
                    error: schoolsError,
                },
                currentSchoolId,
                setCurrentSchoolId,
            }}
        >
            <Router>
                {isLoggedInAndAuthorized && <Header />}
                <div className="wrapper">
                    {isLoggedInAndAuthorized && <Sidebar handleLogout={logout} userRole={userRole} />}
                    <div id="content">
                        <Switch>
                            <Route
                                exact
                                path="/login"
                                render={() => <Login isLoggedIn={isLoggedInAndAuthorized} handleLogin={login} />}
                            />
                            {(!isLoggedIn || !isAuthorized) && (
                                <>
                                    <Redirect to="/" />
                                    <Route path="/" component={Start} />
                                </>
                            )}

                            <Status isLoaded={meData?.schoolId && (areSchoolsLoaded || userRole !== 'SupportAdmin')}>
                                <Switch>
                                    <Redirect exact path="/" to="/dashboard" />
                                    <Route path="/dashboard" component={Dashboard} />
                                    {/* <Route path="/activity-logs" component={DiagnosticsListing} /> */}
                                    <Route path="/messages" component={MessagesListing} />
        
                                    <Route path="/labs/:id" component={Lab} />
                                    <Route path="/labs" component={LabsListing} />
        
                                    {/* <Route path="/virtual-machines/:id" component={VirtualMachine} /> */}
                                    {/* <Route path="/virtual-machines" component={VirtualMachinesListing} /> */}
                                    <Route path="/virtual-networks/:id/" component={VirtualNetwork} />
                                    <Route path="/virtual-networks/" component={VirtualNetworksListing} />
        
                                    {/* <Route path="/scripts/:id" component={Script} /> */}
                                    {/* <Route path="/scripts" component={ScriptsListing} /> */}
        
                                    {/* <Route path="/vhd" component={VhdListing} /> */}
        
                                    {/* <Route path="/groups/:groupId/:userId" component={VirtualMachine} /> */}
                                    <Route path="/groups/:groupId" component={GroupUsersListing} />
                                    <Route path="/groups" component={GroupsListing} />
        
                                    <Route path="/users" component={UsersListing} />
        
                                    {/* <Route path="/users/:userId" component={User} /> */}
                                    {/* <Route path="/users" component={UsersListing} /> */}
        
                                    <Route path="/costs" component={CostsListing} />
                                    <Route path="/settings" component={Settings} />
                                    {/* <Route path="/downloads" component={Downloads} /> */}
                                    <Route component={NoMatch} />
                                </Switch>
                            </Status>

                            <Route component={NoMatch} />
                        </Switch>
                    </div>
                </div>
                <Footer />
                <Notification />
                <div id="modal-root" />
            </Router>
        </GlobalContext.Provider>
    )
}

export default App
