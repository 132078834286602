import React from 'react'
import { Link } from 'react-router-dom'

const renderGroups = data => (
    <>
        {data?.map(({ id, name }) => (
            <li key={name} className="list-group-item">
                <Link className="btn btn-link" to={`/groups/${id}`} style={{ width: '100%' }}>
                    <span className="img-ico">{name[0]}</span>
                    <span className="list-name">{name}</span>
                </Link>
            </li>
        ))}
    </>
)

const GroupsListing = ({ data }) => (
    <div className="page-content user-groups-list">
        <ul className="list-group list-group-flush">{renderGroups(data)}</ul>
    </div>
)

export default GroupsListing
