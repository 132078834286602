import React from 'react'
import { API } from '@signpost/common/src/helpers/api'
import { ContentHeader } from 'components'
import VirtualNetwork from './VirtualNetwork'

export default class VirtualNetworksPage extends React.Component {
    state = {
        error: null,
        isLoaded: false,
        data: []
    }

    componentDidMount = () => {
        fetch(`${API.baseUrl}/virtual-networks/?`, {  // endpoint? nie ma jeszcze API
            method: 'GET',
            headers: API.headers
        })
            .then(res => res.json())
            .then(
                result => {
                    this.setState({
                        isLoaded: true,
                        data: result
                    })
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                error => {
                    this.setState({
                        isLoaded: true,
                        error
                    })
                }
            )
    }

    render() {
        const { error, isLoaded, data } = this.state
        const { match } = this.props
        const { id } = match.params
        const filteredItem = data.VirtualNetworks && data.VirtualNetworks.filter(item => item.Name === id)[0]

        return (
            <>
                <ContentHeader title="Virtual Network" subtitle={filteredItem && filteredItem.Name} />
                <VirtualNetwork error={error} isLoaded={isLoaded} item={filteredItem} />
            </>
        )
    }
}
