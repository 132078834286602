import React from 'react'
import get from 'lodash/get'

const Status = ({ error, isLoaded, children }) => {
    const errorText = get(error, ['data', 'Message'], 'Request failed') || 'Request failed'

    if (!isLoaded) {
        return <div className="loader" />
    }

    if (error) {
        return <div>Error: {errorText}</div>
    }

    return children
}

export default Status
