import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import 'styles/Dashboard.css'

const Dashboard = ({ data = {}, userRole }) => {
    const cardsConfig = [
        {
            path: '/messages',
            icon: 'fa-comment-dots',
            title: 'Messages',
            count: data?.amountOfUnreadMessages,
            status: 'Unhandled',
            hiddenForRoles: ['SupportAdmin']
        },
        {
            path: '/labs',
            icon: 'fa-flask',
            title: 'Labs',
            count: data?.numberOfPublishedLabs,
            status: 'Available',
            hiddenForRoles: []
        },
        {
            path: '#',
            icon: 'fa-server',
            title: 'Virtual machines',
            count: data?.amountOfOnlineVirtualMachines,
            status: 'Online',
            hiddenForRoles: []
        },
        {
            path: '/virtual-networks',
            icon: 'fa-network-wired',
            title: 'Virtual networks',
            count: data?.numberOfVirtualNetworks,
            status: 'Available',
            hiddenForRoles: []
        },
        {
            path: '#',
            icon: 'fa-user-friends',
            title: 'Users',
            count: data?.numberOfUsers,
            status: '',
            hiddenForRoles: []
        }
    ]

    return (
        <div className="page-content dashboards bg-tr">
            {cardsConfig
                .filter(({ hiddenForRoles }) => !hiddenForRoles.includes(userRole))
                .map(({ title, path, icon, count, status }) => {
                    return (
                        <Link
                            key={title}
                            to={path}
                            className="dashboard"
                            style={path === '#' ? { background: '#FBFBFB', pointerEvents: 'none' } : {}}
                        >
                            <div className="dashboard-ico-container">
                                <span className="dashboard-ico">
                                    <i className={'fas ' + icon} />
                                </span>
                            </div>
                            <h3 className="ellipses">{title}</h3>
                            <p className="dashboard-text">
                                {count} {status}
                            </p>
                        </Link>
                    )
                })}
        </div>
    )
}

Dashboard.propTypes = {
    data: PropTypes.shape({
        amountOfOnlineVirtualMachines: PropTypes.number,
        amountOfUnreadMessages: PropTypes.number,
        numberOfPublishedLabs: PropTypes.number,
        numberOfUsers: PropTypes.number,
        numberOfVirtualNetworks: PropTypes.number
    }).isRequired,
    userRole: PropTypes.string.isRequired
}

export default Dashboard
