import React from 'react'

const Button = ({ className = 'btn-action btn-sm', icon, text, disabled, hidden, isLoading, loadingLabel, onClick }) => {
    const isDisabled = disabled || isLoading

    return (
        !hidden && (
            <button
                type="button"
                className={`btn ${className}`}
                disabled={isDisabled}
                onClick={isDisabled ? null : onClick}
            >
                {isLoading ? (
                    <>
                        {loadingLabel && <span className="loader-button-label">{loadingLabel}</span>}
                        <div className="loader" />
                    </>
                ) : (
                        <>
                            {icon && <i className={icon} />} {text}
                        </>
                    )}
            </button>
        )
    )
}


export default Button
