import React from 'react'

const Switch = ({
    label,
    name,
    id,
    className = 'custom-control-input',
    placeholder,
    value,
    isRequired,
    onChange,
    onClick,
}) => (
    <div className="form-group custom-control custom-switch">
        <input
            type="checkbox"
            id={id || name}
            className={className}
            placeholder={placeholder}
            value={value}
            required={isRequired}
            onChange={e => onChange && onChange(e, name)}
            onClick={onClick}
        />
        {label && <label htmlFor={id || name} className="custom-control-label" onClick={onClick}>{label}</label>}
    </div>
)

export default Switch
