import React from 'react'
import 'bootstrap'
import { Table } from 'components'

const Listing = ({ data, handleShowAddCreditsModal, handleShowRejectModal }) => (
    <Table
        head={[
            ['Subject', 25],
            ['From', 26],
            ['Full Name', 20],
            ['Timestamp', 14],
            ['', 15]
        ]}
        body={{
            config: [
                { key: 'title', hiddenValueKey: 'description' },
                { key: 'userEmail' },
                { key: 'userFullName' },
                { key: 'createTimeStamp', dateFormat: 'YYYY-DD-MM' },
                {
                    key: 'customKey',
                    buttons: [
                        { text: 'Accept', className: 'btn-success', onClick: handleShowAddCreditsModal },
                        { text: 'Reject', className: 'btn-danger', onClick: handleShowRejectModal }
                    ]
                }
            ],
            data
        }}
        disableKey="isHandled"
    />
)

export default Listing
