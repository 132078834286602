import React from 'react'
import { Link } from 'react-router-dom'
import 'bootstrap'
import 'styles/VirtualMachines.css'

const renderListElements = data => (
    <>
        {data &&
            data.map(({ id, name }) => (
                <li className="list-group-item" key={id}>
                    {/* <Link className="btn btn-link" to={`/virtual-networks/${name}`}> */}
                        <span className="list-name">
                            <i className="fas fa-ethernet" /> {name}
                        </span>
                    {/* </Link> */}
                </li>
            ))}
    </>
)

const Listing = ({ data }) => (
    <div className="page-content user-groups-list">
        <ul className="list-group list-group-flush">{renderListElements(data)}</ul>
    </div>
)

export default Listing
