import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'components'
import 'styles/Login.css'

const Start = () => {
    return (
        <div className="aligner login-page">
            <div className="page-content">
                <Link to="login">
                    <Button text="Login with MSAL" />
                </Link>
            </div>
        </div>
    )
}
export default Start
