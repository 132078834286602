import React, { useState, useEffect } from "react";
import { Button } from "@signpost/common/src/components";

import Layout from "./Layout";

const LocalState = ({
  isLoaded,
  error,
  title,
  message,
  children,
  confirmButtonText = "Save",
  componentsTypes,
  initialValues,
  minHeight,
  handleHide,
  handleSave,
}) => {
  const [state, setState] = useState(initialValues);

  const onCheckboxChange = (name) =>
    setState({ ...state, [name]: !state[name] });

  return (
    <Layout
      isLoaded={isLoaded}
      error={error}
      title={title}
      minHeight={minHeight}
      handleHide={handleHide}
    >
      <div className="sc-modal-message">{message}</div>
      <div className="sc-modal-message">
        {children.map((child, index) => {
          if (componentsTypes[index] === "checkbox") {
            return {
              ...child,
              props: {
                ...child.props,
                isChecked: state[child.props.name],
                handleChange: onCheckboxChange,
              },
            };
          }
        })}
      </div>
      <div className="sc-modal-footer">
        <Button text="Cancel" isDark onClick={handleHide} />
        <Button text={confirmButtonText} onClick={() => handleSave(state)} />
      </div>
    </Layout>
  );
};

export default LocalState;
