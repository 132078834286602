import React, { useState, useEffect } from 'react'
import { adminGet, adminPut, URLS } from '@signpost/common/src/helpers/api'
import { useAdminGet } from '@signpost/common/src/helpers/customHooks'
import { ContentHeader, Status } from 'components'
import Settings from './Settings'
import { errorToast, successToast } from '@signpost/common/src/helpers/requestStatus'

const defaultFormData = {
    isAutoShutdownPolicyForCreditsLevelEnabled: true,
    creditsLevelForAutoShutdownPolicy: 50,
    creditsLevelForAutoShutdownWarning: 100,
    studentsMaxCreditsLevel: 5000,
    teachersMaxCreditsLevel: 10000
}

const SettingsContainer = () => {
    const [isLoaded, setIsLoadeed] = useState(true)
    const [error, setError] = useState(null)
    const [formData, setFormData] = useState(defaultFormData)

    const [isUpdateLoaded, setIsUpdateLoadeed] = useState(true)

    const fetchData = async () => {
        setIsLoadeed(false)
        try {
            const result = await adminGet({ url: URLS.schools.settings })
            setFormData(result.data?.[0])
        } catch (error) {
            setError(error)
        }
        setIsLoadeed(true)
    }

    useEffect(() => {
        fetchData()
    }, [])

    const updateSettings = async () => {
        const url = URLS.schools.settings
        const body = formData

        setIsUpdateLoadeed(false)

        try {
            await adminPut({ url, body })
            successToast('Settings updated successfully!')
        } catch (error) {
            errorToast('Update failed!')
        }
        setIsUpdateLoadeed(true)
    }

    const handleChange = (e, name) => {
        if(!name) {
            return setFormData({
                ...formData,
                isAutoShutdownPolicyForCreditsLevelEnabled: !formData.isAutoShutdownPolicyForCreditsLevelEnabled
            })
        }
        setFormData({ ...formData, [name]: parseInt(e.target.value) })
    }

    return (
        <>
            <ContentHeader title="Settings" />
            <Status isLoaded={isLoaded} error={error}>
                <Settings
                    isLoaded={isUpdateLoaded}
                    formData={formData}
                    handleChange={handleChange}
                    handleSave={updateSettings}
                />
            </Status>
        </>
    )
}

export default SettingsContainer
