import React, { useState } from 'react'
import 'bootstrap'
import { adminPut, URLS } from '@signpost/common/src/helpers/api'
import { useAdminGet } from '@signpost/common/src/helpers/customHooks'

import { ContentHeader, Status } from 'components'
import { Modal } from 'components/Modal'
import { Confirm, Form } from './components'
import Listing from './Listing'

const ListingContainer = () => {
    const [isAddCreditsModal, setIsAddCreditsModal] = useState(false)
    const [isRejectModal, setIsRejectModal] = useState(false)
    const [currentRow, setCurrentRow] = useState({})
    const [handledIds, setHandledIds] = useState([])
    const [isDeleted, setIsDeleted] = useState(true)
    const [deletedError, setDeletedError] = useState(null)
    const [updatedData, setUpdatedData] = useState(null)
    const [credits, setCredits] = useState(1)

    const { isLoaded, error, data: handledData } = useAdminGet(URLS.requests.handledMessages)
    const { isLoaded: isLoadedOld, error: errorOld, data: freshData } = useAdminGet(URLS.requests.unhandledMessages)

    const allData = (handledData && freshData && [...freshData, ...handledData]) || []
    const isModal = isAddCreditsModal || isRejectModal

    const handleToggleAddCreditsModal = row => {
        setCurrentRow(row || {})
        setIsAddCreditsModal(!isAddCreditsModal)
        setCredits(1)
    }
    const handleToggleRejectModal = row => {
        setCurrentRow(row || {})
        setIsRejectModal(!isRejectModal)
    }
const handleUpdateData = newHandledIds => {
    const freshNotHandledData = freshData.filter(message => !newHandledIds.includes(message.id))
        const freshHandledData = freshData.reduce((acc, message) => {
            if (newHandledIds.includes(message.id)) {
                return [...acc, { ...message, isHandled: true }]
            }
            return acc
        }, [])

        setUpdatedData([...freshNotHandledData, ...freshHandledData, ...handledData])
    }

    const handleUpdateHandledIds = id => {
        const newHandledIds = [id, ...handledIds]
        setHandledIds(newHandledIds)
        handleUpdateData(newHandledIds)
    }

    const handleUpdateMessages = async ({ isAccepted }) => {
        setIsDeleted(false)

        const url = URLS.requests.update
        const creditsCount = isAccepted ? { credits: parseInt(credits) } : ''
        const body = {
            messageId: currentRow.id,
            isAccepted,
            ...creditsCount
        }
        try {
            await adminPut({ url, body })
            handleUpdateHandledIds(currentRow.id)
            setIsDeleted(true)
            setIsRejectModal(false)
            setIsAddCreditsModal(false)
            setCurrentRow({})
        } catch (err) {
            setIsDeleted(true)
        }
    }

    const handleAcceptCreditsRequest = row => {
        handleUpdateMessages({ isAccepted: true })
        setCurrentRow(row)
    }

    const handleReject = () => {
        handleUpdateMessages({ isAccepted: false })
    }

    const handleSetCredits = e => {
        const { value } = e.target

        if (value < 0) {
            return setCredits(0)
        }

        return setCredits(value)
    }

    return (
        <>
            <ContentHeader title="Messages" />
            <Status isLoaded={isLoaded && isLoadedOld} error={error || errorOld}>
                <Listing
                    data={updatedData || allData}
                    handleShowAddCreditsModal={handleToggleAddCreditsModal}
                    handleShowRejectModal={handleToggleRejectModal}
                />
            </Status>
            {isModal && (
                <Modal>
                    {isAddCreditsModal && (
                        <Form
                            isLoaded={isDeleted}
                            error={deletedError}
                            currentRow={currentRow}
                            handleHide={handleToggleAddCreditsModal}
                            handleSave={handleAcceptCreditsRequest}
                            handleSetCredits={handleSetCredits}
                        />
                    )}
                    {isRejectModal && (
                        <Confirm
                            isLoaded={isDeleted}
                            error={deletedError}
                            id={currentRow.id}
                            message="Are you sure you want to reject this message?"
                            confirmButtonText="Reject"
                            handleHide={handleToggleRejectModal}
                            handleConfirm={handleReject}
                        />
                    )}
                </Modal>
            )}
        </>
    )
}

export default ListingContainer
