import React, { useState } from 'react'
import { useAdminGet } from '@signpost/common/src/helpers/customHooks'
import { ContentHeader, SearchBar, Status } from 'components'
import { Button } from '@signpost/common/src/components'
import GroupsListing from './GroupsListing'
import GroupsManage from '../GroupsManage'
import { URLS } from '@signpost/common/src/helpers/api'

const GroupsListingContainer = () => {
    const [isAddToGroupOpen, setIsAddToGroupOpen] = useState(false)
    const buttonLabel = isAddToGroupOpen ? 'Hide Add New Group' : 'Show Add New Group';

    const { isLoaded, error, data, getDataFromAPI } = useAdminGet(URLS.groups.all)

    const toggleAddingToGroup = () => setIsAddToGroupOpen(!isAddToGroupOpen)

    const openAddingButtonStyles = {
        textAlign: 'right',
        padding: '0 16px 20px',
    }

    const handleSubmitAdd = (success) => {
        if (success) {
            getDataFromAPI();
        }
        toggleAddingToGroup();
    };


    return (
        <>
            <ContentHeader title="Groups" />
            <Status isLoaded={isLoaded} error={error}>
                <div style={openAddingButtonStyles}>
                    <Button text={buttonLabel} onClick={toggleAddingToGroup} />
                </div>
                <GroupsListing data={data} />
            </Status>
            {isAddToGroupOpen && <GroupsManage onSubmit={handleSubmitAdd} />}
        </>
    )
}

export default GroupsListingContainer
