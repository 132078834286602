import React from 'react'
import { Button, Status } from 'components'
import 'bootstrap'
import { handleDisplayPlural } from '@signpost/common/src/helpers/string'

export const handleExtendSizeName = (name, coreCount, memoryCount) => 
    `${name} / ${coreCount} Core${handleDisplayPlural(coreCount)} / ${Math.floor(memoryCount / 1024)} GB`

export const handleExtendSizesNames = (dataSizes, sizeName) => {
    if (!sizeName) {
        return dataSizes.map(({ name, coreCount, memoryCount }) => handleExtendSizeName(name, coreCount, memoryCount))
    }

    const currentSizeData = dataSizes && dataSizes.find(el => el.name === sizeName)
    if (currentSizeData) {
        const { name, coreCount, memoryCount } = currentSizeData
        return handleExtendSizeName(name, coreCount, memoryCount)
    }
}

const VMT = ({ data, fetchStatus, dataSizes, isLoadingButton, handleToggleModal }) => {
    const renderTbody = bodyData =>
        bodyData?.map(({ id, templateName, sizeName, galleryImageName, extensionEnabled }) => (
            <tr key={id}>
                <td>
                    <span>{templateName}</span>
                </td>
                <td>
                    <span>{galleryImageName ? 'Gallery Image' : 'Custom Image'}</span>
                </td>
                <td>
                    <span>{handleExtendSizesNames(dataSizes, sizeName)}</span>
                </td>
                <td>
                    <span>{galleryImageName}</span>
                </td>
                <td>
                    <span>{extensionEnabled ? 'Azure AD' : 'Local users'}</span>
                </td>
            </tr>
        ))

    return (
        <Status isLoaded={fetchStatus.isTemplateLoaded} error={fetchStatus.templateError}>
            <div className="page-content">
                <div className="clearfix">
                    <Button
                        icon="fas fa-plus"
                        text="Add"
                        className="btn-action btn-sm isRight"
                        isLoading={isLoadingButton}
                        loadingLabel="creating VN"
                        onClick={() => handleToggleModal('show')}
                    />
                    <h3>Virtual machine template</h3>
                </div>
                <div className="virtual-machines">
                    <table className="base-table break-word">
                        <thead>
                            <tr>
                                <th scope="col" style={{ width: '16%' }}>
                                    VM{' '}
                                </th>
                                <th scope="col" style={{ width: '18%' }}>
                                    Type
                                </th>
                                <th scope="col" style={{ width: '24%' }}>
                                    Size
                                </th>
                                <th scope="col" style={{ width: '26%' }}>
                                    Image
                                </th>
                                <th scope="col" style={{ width: '16%' }}>
                                    Login
                                </th>
                            </tr>
                        </thead>
                        <tbody>{data && renderTbody(data)}</tbody>
                    </table>
                </div>
            </div>
        </Status>
    )
}

export default VMT
