import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import "./Tabs.css";

const renderTabs = config => {
  return config.map(({ name, label }, index) => (
    <Tab
      key={name}
      value={name}
      label={label}
      id={`wrapped-tab-${name}`}
      aria-controls={`wrapped-tabpanel-${name}`}
    />
  ));
};

const renderViews = (config, activeTabName) =>
  config.map(({ name, component }) => (
    <div
      key={name}
      id={`wrapped-tabpanel-${name}`}
      aria-labelledby={`wrapped-tab-${name}`}
      className={`sc-tab-view${activeTabName === name ? " isShowed" : ""}`}
    >
      {component}
    </div>
  ));

export const TabsContianer = ({ config, activeTabName, handleChooseTab }) => (
  <AppBar position="static" className="sc-tab">
    <Tabs
      value={activeTabName}
      onChange={handleChooseTab}
      aria-label="wrapped label tabs example"
    >
      {renderTabs(config)}
    </Tabs>
    {renderViews(config, activeTabName)}
  </AppBar>
);

export default TabsContianer;
