import React, { useState, useEffect } from 'react'
import { ContentHeader, SearchBar, Status } from 'components'
import { URLS, adminGet } from '@signpost/common/src/helpers/api'
import { useAdminGet } from '@signpost/common/src/helpers/customHooks'
import Listing from './Listing'
import { CreateLabModal as Modal } from '../../components'

const ListingContainer = () => {
    const [data, setData] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)
    const [error, setError] = useState('')

    const { isLoaded: isLoadedVirtualNetworks, error: errorVirtualNetworks, data: dataVirtualNetworks } = useAdminGet(
        URLS.virtualNetworks.all
    )
    const { isLoaded: isLoadedGroups, error: errorGroups, data: dataGroups } = useAdminGet(URLS.groups.all)
    const { isLoaded: isLoadedRegions, error: errorRegions, data: dataRegions } = useAdminGet(URLS.regions)
    const [isModal, setIsModal] = useState(false)
    const [filter, setFilter] = useState('')

    const refreshData = async () => {
        setIsLoaded(false)
        try {
            const res = await adminGet({ url: URLS.labs.all })
            setData(res.data)
        } catch (err) {
            setError(err)
        }
        setIsLoaded(true)
    }

    useEffect(() => {
        refreshData()
    }, [])

    const isModalLoaded = isLoadedVirtualNetworks && isLoadedGroups && isLoadedRegions
    const modalError = errorVirtualNetworks || errorGroups || errorRegions

    const handleToggleModal = action => {
        setIsModal(action === 'show')
    }
    const handleFilter = e => {
        setFilter(e.target.value)
    }

    const filteredData = data ? data.filter(({ name }) => name.toLowerCase().includes(filter.toLowerCase())) : []

    return (
        <>
            <ContentHeader title="Labs" />
            <SearchBar
                value={filter}
                onChange={handleFilter}
                label="Find lab:"
                placeholder="Name of the lab"
                buttonText="Create"
                onButtonClick={() => handleToggleModal('show')}
            />
            <Status isLoaded={isLoaded} error={error}>
                <Listing data={filteredData} />
            </Status>
            {isModal && (
                <Modal
                    isLoaded={isModalLoaded}
                    error={modalError}
                    dataVirtualNetworks={dataVirtualNetworks}
                    dataGroups={dataGroups}
                    dataRegions={dataRegions}
                    handleToggleModal={handleToggleModal}
                    handleRefreshData={refreshData}
                />
            )}
        </>
    )
}

export default ListingContainer
