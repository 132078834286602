import React from 'react'
import { Input } from 'components'

const SearchBar = ({ value, label, placeholder, buttonText, onButtonClick, onChange }) => (
    <div className="page-content">
        {buttonText && (
            <div className="form-group clearfix">
                <button type="button" onClick={onButtonClick} className="btn btn-action btn-sm float-right">
                    <i className="fas fa-plus" /> {buttonText}
                </button>
            </div>
        )}
        <Input
            label={label}
            type="search"
            name="search"
            placeholder={placeholder}
            className="form-control-sm form-control"
            value={value}
            onChange={onChange}
        />
    </div>
)

export default SearchBar
