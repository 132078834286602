import React, { useState } from "react";
import { format } from "date-fns";
import { DateRangePicker as DatePicker } from "react-date-range";
import { Button, OutsideClick } from "../";
import "./DateRangePicker.css";

const noRanges = { startDate: null, endDate: null };

const DateRangePicker = ({ isDisabled, onChange }) => {
  const [ranges, setRanges] = useState(noRanges);

  const isDateChosen = ranges.endDate;

  const renderChosenDate = () => {
    return isDateChosen ? (
      <>
        {format(new Date(ranges.startDate), "dd-MMM-yyyy")}
        {" - "}
        {format(new Date(ranges.endDate), "dd-MMM-yyyy")}
      </>
    ) : (
      "Select date range"
    );
  };

  const handleSelectDateRange = ranges => {
    const { range1 } = ranges;

    setRanges({
      startDate: new Date(range1.startDate),
      endDate: new Date(range1.endDate)
    });
    onChange(ranges);
  };

  const handleReset = () => {
    setRanges(noRanges);
    onChange("reset");
  };

  const renderDateRangePicker = (node, isOpen, handleToggleIsOpen) => (
    <div
      ref={node}
      className={`sc-date-range-picker${isOpen ? " isOpen" : ""}`}
    >
      <DatePicker
        ranges={[ranges]}
        weekStartsOn={1}
        rangeColors={["#8ec154"]}
        onChange={handleSelectDateRange}
      />
      <Button
        text={renderChosenDate(ranges)}
        icon="fas fa-calendar-alt"
        isWhite
        isDisabled={isDisabled}
        onClick={handleToggleIsOpen}
        onReset={isDateChosen ? handleReset : null}
      />
    </div>
  );

  return (
    <OutsideClick
      render={({ node, isOpen, handleToggleIsOpen }) =>
        renderDateRangePicker(node, isOpen, handleToggleIsOpen)
      }
    />
  );
};

export default DateRangePicker;
